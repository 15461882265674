body {
    background-color: #253453
}

.text-white {
    color: white
}

.input_file {
    display: block;
    visibility: hidden;
    width: 0;
    height: 0;
}

.text-input-dotted {
    border: 0;
    border-bottom: 1px dotted #cccccc; 
    padding: 7px 10px; 
    width: 100%
}

.bs-callout {    
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    font-size: 12px
}
.bs-callout h5 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: bold
}
.bs-callout p:last-child {
    margin-bottom: 0;
}
.bs-callout code {
    border-radius: 3px;
}
.bs-callout+.bs-callout {
    margin-top: -5px;
}
.bs-callout-default {
    border-left-color: #777;
}
.bs-callout-default h5 {
    color: #777;
}
.bs-callout-primary {
    border-left-color: #428bca;
}
.bs-callout-primary h5 {
    color: #428bca;
}
.bs-callout-success {
    border-left-color: #5cb85c;
}
.bs-callout-success h5 {
    color: #5cb85c;
}
.bs-callout-danger {
    border-left-color: #d9534f;
}
.bs-callout-danger h5 {
    color: #d9534f;
}
.bs-callout-warning {
    border-left-color: #f0ad4e;
}
.bs-callout-warning h5 {
    color: #f0ad4e;
}
.bs-callout-info {
    border-left-color: #5bc0de;
}
.bs-callout-info h5 {
    color: #5bc0de;
}