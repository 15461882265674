.tree,
.tree ul {
  margin:0;
  padding:0;
  list-style:none;
}

.tree ul {
  margin-left:0.5em; /* indentation */
  position:relative;
}

.tree ul ul {margin-left:.5em} /* (indentation/2) */

.tree ul:before {
  content:"";
  display:block;
  width:0;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  border-left:1px solid;
}

.tree li {
  margin:0;
  padding:0 0 0 1.5em; /* indentation + .5em */
  line-height:1.6em; /* default list item's `line-height` */
  color:#369;
  position:relative;
}

.tree ul li:before {
  content:"";
  display:block;
  width:10px; /* same with indentation */
  height:0;
  border-top:1px solid;
  margin-top:-1px; /* border top width */
  position:absolute;
  top:1em; /* (line-height/2) */
  left:0;
}

.tree ul li:last-child:before {
  background:white; /* same with body background */
  height:auto;
  top:1em; /* (line-height/2) */
  bottom:0;
}